import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Masonry from 'react-masonry-component'
import Layout from '../components/layout'
import SEO from '../components/seo'
import {FormattedMessage} from 'react-intl'
import {filterByLang} from '../utility/utils'


const masonryOptions = {
  transitionDuration: 0
};


const useToggleFilters = (initialState = {}) => {
  const [filters, setFilters] = useState(initialState);
  const toggleFilter = item => setFilters(current => ({...current, [item]: !current[item]}));
  const clearFilters = () => setFilters({});
  return [filters, toggleFilter, clearFilters];
}

const Shop = ({ pageContext: { locale } })  => {
  
  const { allContentfulContenutoGenerico, allContentfulShop } = useStaticQuery(
    graphql`
      query {
        allContentfulContenutoGenerico(filter: {contentful_id: {eq: "32jxzcjWnws4C7OmdOdzXo"}}) {
          edges {
            node {
              node_locale
              claim {
                childMarkdownRemark {
                  html
                }
              }
              testo {
                childMarkdownRemark {
                  html
                }
              }
            }
          }
        } 
        allContentfulShop(sort: {fields: [dataOrdinamento], order: DESC}) {
          edges {
            node {
              titolo
              node_locale
              contentful_id
              copertina {
                fluid(maxWidth: 450) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
              Sezione
              linkEtsy
              didascalia
            }
          }
        }
      }
    `
  );  
  let filterLabel = [];
  const products = filterByLang(allContentfulShop,locale);
  const intro = filterByLang(allContentfulContenutoGenerico,locale)[0];
  products.forEach((element) => {
  if(element.Sezione) 
    element.Sezione.forEach((sezione) => {
      if (filterLabel.indexOf(sezione) === -1) filterLabel.push(sezione);
    });
  });   

  const [tagFilters, toggleTagFilter] = useToggleFilters();
  const activeTagFilters = Object.keys(tagFilters).filter(tag => !!tagFilters[tag]);
  const filterByTags = product => !activeTagFilters.length || activeTagFilters.find(tag => product.Sezione.includes(tag));
  const filteredProduct = products.filter(filterByTags);
   
  return (
    <Layout locale={locale}>
      <SEO title="Shop" />
      <div className="row">
        <div className="col-12 text-center">
          <FormattedMessage id="shop">
              {txt => <h1 className="pagetitle">{txt}</h1>}
          </FormattedMessage>
        </div>
      </div>
      
      <div className="section section--top shop">
        <div className="row">
          <div className="col-12">
            {locale === 'it' ? (
              <div className="section__text section__row">
                
                <div  className="section__text" dangerouslySetInnerHTML={{__html: intro.testo.childMarkdownRemark.html}} />
              </div>
            ):(
              <div className="section__text section__row">
                  English version
              </div>
            )}
          </div>
        </div>
       {/*  <div className="row mt-1 mb-5">
          <div className="col-12 text-center">
              <Link to="https://giuliaclerici.bigcartel.com/products" className="btn-action mx-5">Vai allo shop</Link>
              <Link to="/contatti" className="btn-action mx-5">Scrivimi</Link>
          </div>
        </div>   */}
 
        {locale === 'it' &&
          <>
            <div className="row">
              <div className="col-12">
                <div className="shop__filters">
                  {filterLabel.map((item) => {
                    return (
                    <button className={`shop__filter${tagFilters[item] ? ' shop__filter--active' : ''}`}  onClick={() => toggleTagFilter(item)} key={item}>
                      {item}
                    </button>    
                    )
                  })
                  }
                </div>
              </div>
            </div>
        
            <Masonry options={masonryOptions}>
                {filteredProduct.map((item,index) => {
                  return (
                    <div  key={item.contentful_id}  className="card">
                      {item.linkEtsy ? ( 
                        <>
                          <a rel="noopener noreferrer" target="_blank" href={item.linkEtsy} className="card__copertinawrapper imghover">
                            <Img  className="card__copertina" fluid={item.copertina.fluid} />
                          </a>
                          <div className="card__title text-center">
                            <a rel="noopener noreferrer" target="_blank" href={item.linkEtsy}>{item.titolo}</a> 
                            {/* <Link to={`/shop/${slugify(item.titolo)}/`}>{item.titolo}</Link> */}
                          </div>
                        </>
                      ) : (
                        <>
                          <div  className="card__copertinawrapper">
                            <Img  className="card__copertina" fluid={item.copertina.fluid} />
                          </div>
                          <div className="card__title text-center">
                              {item.titolo}
                          </div>
                        </>
                      )}

                     
                      <div className="card__didascalia text-center">{item.didascalia}</div>
                    </div>
                  )
                })}
              </Masonry> 
            </>
          }
      </div>
    </Layout>
  )
}
export default Shop
